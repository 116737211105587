<style lang="sass" scoped>

</style>
<template lang="pug">
div
  router-view
  h5 count Record by Name
  .row
  div(v-for='tag in tags_stat.count')
    button.btn.btn-light.btn-block(type='button' @click='view_tag(tag)')
      .row
        .col
          b-progress.border(class="mt-2" :max="rows_count" show-value)
            b-progress-bar(:value="tag.count" variant="success")
        .col
          em {{ Number((tag.count/rows_count)*100).toFixed(2) }}%
        .col
          h3 {{tag.name}}
  //- pre {{tags}}
  //- pre {{tags_rows}}
  //- pre {{tags_stat}}
  //- pre {{selected_tags}}
  .pt-4
  .pt-4
  h5 선택된 태그:
    span.p-4.bg-light.mr-2.shadow(v-for='tag in selected_tags') {{tag.name}}

  br
  br
  h1(v-show='selected_tags.length < 2') 2개 이상 태그 선택

  .container
    div(style='width: 610px; height: 600px' v-if='segments.loaded')
      //- div.float-left(style='position: relative; height: 600px; left: -100px; margin-right: -100px')
        span B
        div(style='height: 500px')
        span B'
      div.float-left.bg-light.border(style='width: 300px; height: 300px')
        strong A'B
          span.float-right
            span.text-secondary {{segments.a_label}} X
            span.px-1.text-muted &middot;
            span.text-primary {{segments.b_label}}
        div(v-if='segments.aN_b.segments')
          //- pre {{segments.aN_b.segments}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong A'B
              span.float-right
                span.text-secondary {{segments.aN_b.segments.a_label}} X
                span.px-1.text-muted &middot;
                span.text-primary {{segments.aN_b.segments.b_label}}
            code.d-block {{segments.aN_b.segments.aN_b.ids.length}}명
            small(v-for='rid in segments.aN_b.segments.aN_b.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong AB
              span.float-right
                span.text-primary {{segments.aN_b.segments.a_label}}
                span.px-1.text-muted &middot;
                span.text-primary {{segments.aN_b.segments.b_label}}
            code.d-block {{segments.aN_b.segments.a_b.ids.length}}명
            small(v-for='rid in segments.aN_b.segments.a_b.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong A'B'
              span.float-right
                span.text-secondary {{segments.aN_b.segments.a_label}} X
                span.px-1.text-muted &middot;
                span.text-secondary {{segments.aN_b.segments.b_label}} X
            code.d-block {{segments.aN_b.segments.aN_bN.ids.length}}명
            small(v-for='rid in segments.aN_b.segments.aN_bN.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong AB'
              span.float-right
                span.text-primary {{segments.aN_b.segments.a_label}}
                span.px-1.text-muted &middot;
                span.text-secondary {{segments.aN_b.segments.b_label}} X
            code.d-block {{segments.aN_b.segments.a_bN.ids.length}}명
            small(v-for='rid in segments.aN_b.segments.a_bN.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
        div(v-else-if='segments.aN_b && segments.aN_b.ids' style='overflow: scroll; height: 95%')
          code.d-block {{segments.aN_b.ids.length}}명
          //- pre {{segments.aN_b}}
          small(v-for='rid in segments.aN_b.ids')
            button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
      div.float-left.bg-light.border(style='width: 300px; height: 300px')
        strong AB
          span.float-right
            span.text-primary {{segments.a_label}}
            span.px-1.text-muted &middot;
            span.text-primary {{segments.b_label}}
        div(v-if='segments.a_b.segments')
          //- pre {{segments.a_b.segments}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong A'B
              span.float-right
                span.text-secondary {{segments.a_b.segments.a_label}} X
                span.px-1.text-muted &middot;
                span.text-primary {{segments.a_b.segments.b_label}}
            code.d-block {{segments.a_b.segments.aN_b.ids.length}}명
            small(v-for='rid in segments.a_b.segments.aN_b.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong AB
              span.float-right
                span.text-primary {{segments.a_b.segments.a_label}}
                span.px-1.text-muted &middot;
                span.text-primary {{segments.a_b.segments.b_label}}
            code.d-block {{segments.a_b.segments.a_b.ids.length}}명
            small(v-for='rid in segments.a_b.segments.a_b.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong A'B'
              span.float-right
                span.text-secondary {{segments.a_b.segments.a_label}} X
                span.px-1.text-muted &middot;
                span.text-secondary {{segments.a_b.segments.b_label}} X
            code.d-block {{segments.a_b.segments.aN_bN.ids.length}}명
            small(v-for='rid in segments.a_b.segments.aN_bN.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong AB'
              span.float-right
                span.text-primary {{segments.a_b.segments.a_label}}
                span.px-1.text-muted &middot;
                span.text-secondary {{segments.a_b.segments.b_label}} X
            code.d-block {{segments.a_b.segments.a_bN.ids.length}}명
            small(v-for='rid in segments.a_b.segments.a_bN.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
        div(v-else-if='segments.a_b && segments.a_b.ids' style='overflow: scroll; height: 95%')
          code.d-block {{segments.a_b.ids.length}}명
          //- pre {{segments.a_b}}
          small(v-for='rid in segments.a_b.ids')
            button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
      div.float-left.bg-light.border(style='width: 300px; height: 300px')
        strong A'B'
          span.float-right
            span.text-secondary {{segments.a_label}} X
            span.px-1.text-muted &middot;
            span.text-secondary {{segments.b_label}} X
        div(v-if='segments.aN_bN.segments')
          //- pre {{segments.aN_bN.segments}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong A'B
              span.float-right
                span.text-secondary {{segments.aN_bN.segments.a_label}} X
                span.px-1.text-muted &middot;
                span.text-primary {{segments.aN_bN.segments.b_label}}
            code.d-block {{segments.aN_bN.segments.aN_b.ids.length}}명
            small(v-for='rid in segments.aN_bN.segments.aN_b.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong AB
              span.float-right
                span.text-primary {{segments.aN_bN.segments.a_label}}
                span.px-1.text-muted &middot;
                span.text-primary {{segments.aN_bN.segments.b_label}}
            code.d-block {{segments.aN_bN.segments.a_b.ids.length}}명
            small(v-for='rid in segments.aN_bN.segments.a_b.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong A'B'
              span.float-right
                span.text-secondary {{segments.aN_bN.segments.a_label}} X
                span.px-1.text-muted &middot;
                span.text-secondary {{segments.aN_bN.segments.b_label}} X
            code.d-block {{segments.aN_bN.segments.aN_bN.ids.length}}명
            small(v-for='rid in segments.aN_bN.segments.aN_bN.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong AB'
              span.float-right
                span.text-primary {{segments.aN_bN.segments.a_label}}
                span.px-1.text-muted &middot;
                span.text-secondary {{segments.aN_bN.segments.b_label}} X
            code.d-block {{segments.aN_bN.segments.a_bN.ids.length}}명
            small(v-for='rid in segments.aN_bN.segments.a_bN.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
        div(v-else-if='segments.aN_bN && segments.aN_bN.ids' style='overflow: scroll; height: 95%')
          code.d-block {{segments.aN_bN.ids.length}}명
          //- pre {{segments.aN_bN}}
          small(v-for='rid in segments.aN_bN.ids')
            button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
      div.float-left.bg-light.border(style='width: 300px; height: 300px')
        strong AB'
          span.float-right
            span.text-primary {{segments.a_label}}
            span.px-1.text-muted &middot;
            span.text-secondary {{segments.b_label}} X
        div(v-if='segments.a_bN.segments')
          //- pre {{segments.a_bN.segments}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong A'B
              span.float-right
                span.text-secondary {{segments.a_bN.segments.a_label}} X
                span.px-1.text-muted &middot;
                span.text-primary {{segments.a_bN.segments.b_label}}
            code.d-block {{segments.a_bN.segments.aN_b.ids.length}}명
            small(v-for='rid in segments.a_bN.segments.aN_b.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong AB
              span.float-right
                span.text-primary {{segments.a_bN.segments.a_label}}
                span.px-1.text-muted &middot;
                span.text-primary {{segments.a_bN.segments.b_label}}
            code.d-block {{segments.a_bN.segments.a_b.ids.length}}명
            small(v-for='rid in segments.a_bN.segments.a_b.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong A'B'
              span.float-right
                span.text-secondary {{segments.a_bN.segments.a_label}} X
                span.px-1.text-muted &middot;
                span.text-secondary {{segments.a_bN.segments.b_label}} X
            code.d-block {{segments.a_bN.segments.aN_bN.ids.length}}명
            small(v-for='rid in segments.a_bN.segments.aN_bN.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
          div.float-left.bg-light.border(style='width: 140px; height: 140px')
            strong AB'
              span.float-right
                span.text-primary {{segments.a_bN.segments.a_label}}
                span.px-1.text-muted &middot;
                span.text-secondary {{segments.a_bN.segments.b_label}} X
            code.d-block {{segments.a_bN.segments.a_bN.ids.length}}명
            small(v-for='rid in segments.a_bN.segments.a_bN.ids')
              button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
        div(v-else-if='segments.a_bN && segments.a_bN.ids' style='overflow: scroll; height: 95%')
          code.d-block {{segments.a_bN.ids.length}}명
          //- pre {{segments.a_bN}}
          small(v-for='rid in segments.a_bN.ids')
            button.btn.btn-default.btn-sm(type='button' @click='open_record(rows_by_id[rid])') {{rows_by_id[rid].customer_text}}
      .clearfix
      //- div.w-100
        span.float-left A'
        span.float-right A

  .clearfix

  //- .container-table.async(:class='{done:done}' v-if='document && document.id')

    table.table
      //- (:style='{width:`${width}px`}')
      thead
        tr
          //- th.sticky-header.shadow-sm(v-for='col in columns' :style='{width:`${col.width}px`}')
          th(style='width: 300px') 제목
          th 내용
          th
          th
            span(v-if='sortby == "created_at"') 생성일
            span(v-else-if='sortby == "updated_at"') 수정일
            span(v-else-if='cols_by_key[sortby] && cols_by_key[sortby].position != "secondary"') {{cols_by_key[sortby].label}}
          //- th(v-for='(col, $index) in document.config.cols' v-if='col.visible_at_table == `Y`' style='min-width: 150px')
            span {{col.label}}
            //- span(v-if='col.label != `+`') {{col.label}}
            //- span(v-else style='opacity: 0.5')
              a.text-muted(href='#')
                b-icon-plus
                | 맞춤항목 추가
      tbody
        tr.hover(v-for='row in rows' :class='{"row-selected": row.is_selected}' @click.keydown.meta.prevent.stop='open_record_new(row)' @click.keydown.ctrl.prevent.stop='open_record_new(row)' @click.exact='open_record(row)' )
          td(@click.prevent.stop='select_row(row)')
            //- input(type='checkbox')
            b-form-checkbox(v-model='row.is_selected' value='true' unchecked-value='false' @click.prevent.stop='')
          td.p-0(style='height: 100%')
            router-link.d-block.link-view-out.py-3.pl-3(style='font-weight: 500;' :to='`/property/${property_id}/customer/${document_id}${saved_filter_prefix}/${$options.filters.encodeText(document.name)}/${row.id}/${encodeURIComponent(row.external_id)}`' @click.native.stop='')
              span(v-for='(colkey, $index) in cols_primary')
                span.mr-2(
                  v-if='$index === 0'
                  :class='{"text-none": (!row.json[colkey])}'
                ) {{row.json[colkey] || '(비어있음)'}}
                span.mr-2(v-else) {{row.json[colkey]}}
              span.d-inline-block.pt-1(v-if='tags_count && tags_by_record_id')
                span.btn.btn-light.btn-sm.d-inline-block.bg-light.rounded.text-muted.mr-1.mb-1.p-1(v-for='tag in tags_by_record_id[row.id]' @click.prevent.stop='filter_tag(tag)') {{tag.name}}
          td
            .row
              //- .col-sm-12.col-3.col-lg(v-for='col in cols_secondary')
                span.text-muted.d-block {{col.label}}
                strong.text-dark {{row.json[col.key] || '-'}}
              .col-3.col-lg(v-for='col in cols_secondary' style='min-width: 120px; padding-bottom: 0.5rem')
                strong.d-block {{col.label}}
                span.text-muted.text-break {{row.json[col.key] || '-'}}
            //- span(v-for='col in cols_secondary')
              strong.d-block {{col.label}}
              small.text-muted {{row.json[col.key] || '-'}}
          td.text-right
            router-link.hover-visible.btn.btn-default.btn-sm.text-secondary(:to='`/property/${property_id}/customer/group/${encodeURIComponent(row.external_id)}`' @click.native.stop='')
              //- b-icon-link.mr-1
              small.mr-2: b-icon-box-arrow-up-right
              | 연결된 레코드
          td
            span(v-if='sortby == "created_at"') {{row.created_at | datetime}}
            span(v-else-if='sortby == "updated_at"') {{row.updated_at | datetime}}
            span(v-else-if='cols_by_key[sortby] && cols_by_key[sortby].position != "secondary"') {{row.json[sortby] }}

              //- span.link-view {{row.json[col.key] || '(조회)'}}
          //- td {{row.json['3']}}
          //- td(v-for='(col, $index) in document.config.cols' v-if='col.visible_at_table == `Y`')
            span(v-if='$index != 0') {{row.json[col.key] || '-'}}
            span(v-else)
              router-link.d-block.link-view-out(:to='`/property/${property_id}/customer/${document_id}/record/${row.external_id}`')
                span.link-view {{row.json[col.key] || '(조회)'}}
        //- tr(v-for='i in fills')
          td value value value
            //- br
            //- | value
            //- br
            //- | value
          td value
          td value
          //- td value
          //- td value
          //- td value
          //- td value
          //- td value
    //- customer-record(
    //-   :property_id='property_id'
    //-   :document='document'
    //-   :document_id='document_id'
    //-   :record_id='record_id'
    //- )
  modal.py-2(name='record' width='700px' :height='`auto`' :scrollable='true'
    transition='record'
    @before-close='record_before_close'
  )
    button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("record")' style='font-size: 1.5rem')
      b-icon-x
    .p-4(:style='{minHeight: min_height}' v-if='record_id' ref='record_container')
      h5 {{document.name}}
        span.text-muted.ml-2 조회


      customer-record(
        :property_id='property_id'
        :document='document'
        :tags='tags'
        :document_id='document_id'
        :record_id='record_id'
        :record_row_id='record_row_id'
      )
      //- @loaded='record_did_loaded'
      //- @updated='record_did_updated'
      //- @tag_updated='record_tag_did_updated'


</template>

<script>

import CustomerRecord from '@/components/CustomerRecord'

import Dropdown from "@/components/Dropdown";
import DropdownContent from "@/components/DropdownContent";
import DropdownItem from "@/components/DropdownItem";
import DropdownBlock from "@/components/DropdownBlock";
import SearchFilter from "@/components/SearchFilter";
import SearchTag from "@/components/SearchTag";

import {uniqBy, difference, intersection} from "lodash"

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'record_id', 'record_row_id', 'saved_filter_id'],
  components: {
    CustomerRecord,
    Dropdown, DropdownContent, DropdownItem, DropdownBlock,
    SearchFilter, SearchTag,
  },
  computed: {
    saved_filter_prefix() {
      return this.saved_filter_id ? '-'+this.saved_filter_id : ''
    },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    },
    'document_id'() {
      this.load()
    },
    'record_id'() {
      this.open_modal()
    },
  },
  data() {
    return {
      done: false,
      adding: false,
      document: {},
      rows: [],
      rows_by_id: {},
      rows_count: 0,
      rows_selected_count: 0,
      fills: Array.from('00000000000000000000000'),

      record_loaded: false,

      dropdown_group_record_active: false,

      filters: [],
      selected_filter: null,

      saved_filter: {},
      form_filter: {
        name: '',
        is_shared: 'N',
      },
      filter_changed: false,

      tags: [],
      tags_by_record_id: {},
      tags_count: null,

      min_height: '90vh',
      should_open_new_window: false,
      selected_all: false,

      sort: 'DESC',
      sortby: 'created_at',
      cols_by_key: {},
      cols_by_key_loaded: false,

      tag_selected_action: '',

      tags_stat: {},
      selected_tags: [],
      tags_rows: [],

      segments: {
        loaded: false,
        'a_label': '',
        'b_label': '',
        'a_b': {},
        'aN_b': {},
        'a_bN': {},
        'aN_bN': {},
      }
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }
    setTimeout(() => {
      // this.filter_did_updated({ key: 'search.customer.value', prekey: 'search.customer', value: 'tim' })
      // this.save_filter()

      // this.selected_tags = [
      //   {
      //     "name": "온라인",
      //     "count": "2"
      //   },
      //   {
      //     "name": "매장",
      //     "count": "2"
      //   },
      //   {
      //     "name": "건강식품",
      //     "count": "2"
      //   },
      //   {
      //     "name": "비타민",
      //     "count": "2"
      //   },
      // ]
      // this.load_segment()
    }, 1000);
  },
  methods: {
    load_segment() {
      // 0 1
      // 2 3
      const ids = this.rows.map(e => e.id)
      const tag1 = this.selected_tags[0].name
      const tag2 = this.selected_tags[1].name
      console.log({tag1, tag2})

      const tag1_ids = this.tags_rows.filter(e => {
        return e.name == tag1
      }).map(e => e.record_id)
      const tag2_ids = this.tags_rows.filter(e => {
        return e.name == tag2
      }).map(e => e.record_id)
      console.log({tag1_ids, tag2_ids})

      // a'b
      let aN_b = {}
      {
        console.log(tag1_ids, ids)
        const aN = difference(ids, tag1_ids)
        console.log(aN)
        const b = tag2_ids
        console.log('b', b)
        aN_b.ids = intersection(aN, b)
      }
      // ab
      let a_b = {}
      {
        a_b.ids = intersection(tag1_ids, tag2_ids)
      }
      // a'b'
      let aN_bN = {}
      {
        const aN = difference(ids, tag1_ids)
        const bN = difference(ids, tag2_ids)
        aN_bN.ids = intersection(aN, bN)
      }
      // ab'
      let a_bN = {}
      {
        const a = tag1_ids
        const bN = difference(ids, tag2_ids)
        a_bN.ids = intersection(a, bN)
      }
      this.segments.aN_b = aN_b
      this.segments.a_b = a_b
      this.segments.a_bN = a_bN
      this.segments.aN_bN = aN_bN
      this.segments.a_label = tag1
      this.segments.b_label = tag2

      if (this.selected_tags[2] && this.selected_tags[3]) {
        const tag3 = this.selected_tags[2].name
        const tag4 = this.selected_tags[3].name
        // this.segments.aN_b.a_label = tag3
        // this.segments.aN_b.b_label = tag4
        this.segments.aN_b.segments = this.load_segment2(this.segments.aN_b.ids, tag3, tag4)
        this.segments.a_b.segments = this.load_segment2(this.segments.a_b.ids, tag3, tag4)
        this.segments.a_bN.segments = this.load_segment2(this.segments.a_bN.ids, tag3, tag4)
        this.segments.aN_bN.segments = this.load_segment2(this.segments.aN_bN.ids, tag3, tag4)
      }

      this.segments.loaded = true
    },
    load_segment2(parent_ids, tag1, tag2) {
      const ids = parent_ids
      console.log({tag1, tag2})

      // const tag1_ids = ids
      const tag1_ids = this.tags_rows.filter(e => {
        return parent_ids.includes(e.record_id) && e.name == tag2
      }).map(e => e.record_id)
      const tag2_ids = this.tags_rows.filter(e => {
        return parent_ids.includes(e.record_id) && e.name == tag2
      }).map(e => e.record_id)
      console.log({tag1_ids, tag2_ids})

      // a'b
      let aN_b = {}
      {
        console.log(tag1_ids, ids)
        const aN = difference(ids, tag1_ids)
        console.log(aN)
        const b = tag2_ids
        console.log('b', b)
        aN_b.ids = intersection(aN, b)
      }
      // ab
      let a_b = {}
      {
        a_b.ids = intersection(tag1_ids, tag2_ids)
      }
      // a'b'
      let aN_bN = {}
      {
        const aN = difference(ids, tag1_ids)
        const bN = difference(ids, tag2_ids)
        aN_bN.ids = intersection(aN, bN)
      }
      // ab'
      let a_bN = {}
      {
        const a = tag1_ids
        const bN = difference(ids, tag2_ids)
        a_bN.ids = intersection(a, bN)
      }
      const segments = {}
      segments.aN_b = aN_b
      segments.a_b = a_b
      segments.a_bN = a_bN
      segments.aN_bN = aN_bN
      segments.a_label = tag1
      segments.b_label = tag2
      return segments
    },
    view_tag(tag) {
      const exists = this.selected_tags.filter(e => e.name == tag.name)
      if (exists.length) {
        this.selected_tags = this.selected_tags.filter(e => e.name != tag.name)
      } else {
        this.selected_tags.push(tag)
      }
      if (this.selected_tags.length >= 2) {
        this.load_segment()
      } else {
        this.segments.loaded = false
      }
    },
    tag_add_selected() {
      this.tag_selected_action = 'add'
      this.$modal.show('tag')
    },
    tag_remove_selected() {
      this.tag_selected_action = 'remove'
      this.$modal.show('tag')
    },

    change_sort() {
      this.sort = {
        'ASC': 'DESC',
        'DESC': 'ASC',
      }[this.sort]
      this.load()

      const prev = '' + window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.sort`]
      const next = JSON.stringify({ sort: this.sort, sortby: this.sortby })
      if (prev != next) {
        window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.sort`] = next
      }
    },
    change_sortby() {
      this.load()

      const prev = '' + window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.sort`]
      const next = JSON.stringify({ sort: this.sort, sortby: this.sortby })
      if (prev != next) {
        window.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.sort`] = next
      }
    },
    open_record_new(row) {
      window.open(`/property/${this.property_id}/customer/${this.document_id}${this.saved_filter_prefix}/${this.$options.filters.encodeText(this.document.name)}/${row.id}/${encodeURIComponent(row.external_id)}`, '_blank')
      return false
    },
    open_record(row) {
      this.$router.push({
        path: `/property/${this.property_id}/customer/${this.document_id}${this.saved_filter_prefix}/${this.$options.filters.encodeText(this.document.name)}/segment/${row.id}/${encodeURIComponent(row.external_id)}`,
      })
    },

    select_all() {
      console.log(11)
      if (this.rows_selected_count == this.rows.length) {
        this.rows.forEach(e => e.is_selected = false)
      } else {
        this.rows.forEach(e => e.is_selected = true)
      }
      this.rows_selected_count = this.rows.filter(e => e.is_selected).length
      this.selected_all = this.rows_selected_count == this.rows.length
    },
    // deselect_all() {
    //   this.rows.forEach(e => e.is_selected = false)
    // },
    select_row(row) {
      row.is_selected = !row.is_selected
      this.rows_selected_count = this.rows.filter(e => e.is_selected).length
      this.selected_all = this.rows_selected_count == this.rows.length
      return
    },
    async submit_filter(slient = false) {
      try {
        // save
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/saved-filters`, {
          filters: JSON.stringify(this.filters),
          is_listed: this.form_filter.is_listed || 'Y',
          is_shared: this.form_filter.is_shared,
          name: this.form_filter.name,
        })
        if (r?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
        // get id
        const id = r.data.id

        this.filter_changed = false

        if (slient === true) {

        } else {
          this.$router.push({
            path: `/property/${this.property_id}/customer/${this.document_id}-${id}/${this.$options.filters.encodeReadableText(this.document.name)}`,
          })

          this.$modal.show('dialog', {
            title: '알림',
            text: '추가했습니다.',
          })
        }

        this.$modal.hide('save-filter')

        return id

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }

    },
    async copy_filter_url() {
      try {
        let id
        if (this.saved_filter_id && this.saved_filter.id) {
          id = this.saved_filter_id
        } else {
          // save
          const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/saved-filters`, {
            filters: JSON.stringify(this.filters),
            is_shared: 'Y',
          })
          if (r?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
          // get id
          id = r.data.id
        }

        // send to clipboard
        const copyToClipboard = (text) => {
          if (window.clipboardData && window.clipboardData.setData) {
              // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
              return window.clipboardData.setData("Text", text);

          }
          else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
              var textarea = document.createElement("textarea");
              textarea.textContent = text;
              textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
              document.body.appendChild(textarea);
              textarea.select();
              try {
                  return document.execCommand("copy");  // Security exception may be thrown by some browsers.
              }
              catch (ex) {
                  console.warn("Copy to clipboard failed.", ex);
                  return false;
              }
              finally {
                  document.body.removeChild(textarea);
              }
          }
        }
        copyToClipboard(`${location.href.replace(location.pathname, '')}/property/${this.property_id}/customer/${this.document_id}-${id}/${this.$options.filters.encodeReadableText(this.document.name)}`)
        this.$bvToast.toast(`복사했습니다.`, {
          title: `알림`,
          variant: 'default',
          solid: true,
          toaster: 'b-toaster-bottom-right',
        })
        // this.$modal.show('dialog', {
        //   title: '알림',
        //   text: '.',
        // })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async send_message_filtered() {
      try {
        if (this.rows_selected_count) {
          this.filter_include_selected()
          // if (confirm(`${this.rows_selected_count}개 선택된 고객을 필터에 추가하고 메시지를 보낼까요?`)) {
        }
        let id

        // save
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/saved-filters`, {
          name: 'send message',
          filters: JSON.stringify(this.filters),
        })
        if (r?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
        // get id
        id = r.data.id

        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}${this.saved_filter_prefix}/${this.$options.filters.encodeReadableText(this.document.name)}/outreach`,
          query: {
            fid: id,
          },
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async save_filter_for_message() {
      try {
        this.form_filter.name = ''
        this.form_filter.is_shared = 'N'
        this.form_filter.is_listed = 'N'
        const new_id = await this.submit_filter(true)

        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}-${new_id}/${this.$options.filters.encodeReadableText(this.document.name)}/outreach`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    open_modal() {
      if (this.record_id) {
        this.$modal.show('record')
      } else {
        this.$modal.hide('record')
      }
    },
    record_did_loaded() {
      this.record_loaded = true
    },
    record_did_updated({external_id}) {
      this.load()
      if (external_id) {
        if (external_id != this.record_id) {
          this.$router.replace({
            params: {
              record_id: external_id,
            }
          })
        }
      }
    },
    record_tag_did_updated() {
      this.load_tags()
    },
    record_before_close(event) {
      if (this.$refs.record_container?.offsetHeight) {
        this.min_height = this.$refs.record_container.offsetHeight + 'px'
      }
      if (!this.record_id) {
        // continue close
        return
      }

      event.cancel()
      if (this.record_id) {
        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}${this.saved_filter_prefix}/${this.$options.filters.encodeText(this.document.name)}/segment`
        })
      }
    },
    async load_tags() {
      const r3 = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/tags`)
      if (r3?.data?.message != 'ok') throw new Error('태그 불러오기 실패: ' + r3?.data?.message)
      const tags = r3.data.rows
      this.tags = uniqBy(tags, 'name')
      this.tags_rows = tags
      const tags_by_record_id = {}
      for (const tag of tags) {
        if (!tags_by_record_id[tag.record_id]) tags_by_record_id[tag.record_id] = []
        tags_by_record_id[tag.record_id].push(tag)
      }
      this.tags_by_record_id = tags_by_record_id
      this.tags_count = this.tags.length

      const r4 = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/tags/stat`)
      if (r4?.data?.message != 'ok') throw new Error('태그 통계 실패: ' + r4?.data?.message)
      this.tags_stat = r4.data
      return
    },
    async load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }
      try {

        if (!this.sort_loaded) {
          const sort = window?.sessionStorage[`noitaler.${this.property_id}.customer.${this.document_id}.sort`]
          if (sort) {
            try {
              const opt = JSON.parse(sort)
              this.sort = opt.sort
              this.sortby = opt.sortby
              this.sort_loaded = true
            } catch (error) {
              console.log('failed to parse', sort)
            }
          }
        }

        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records`, {
          params: {
            filters: '[]',
            sort: this.sort,
            sortby: this.sortby,
          }
        })
        this.rows = r.data.rows.map(e => {
          e.json = JSON.parse(e.json) || {}
          e.is_selected = false
          return e
        })
        const rows_by_id = {}
        for (const r of this.rows) {
          rows_by_id[r.id] = r
        }
        console.log(rows_by_id)
        this.rows_by_id = rows_by_id
        this.rows_count = r.data.rows_count
        this.rows_selected_count = 0

        this.cols_primary = []
        this.cols_secondary = []
        const cols_by_key = {}

        for (const col of this.document.config.cols) {
          if (col.position == 'primary') {
            this.cols_primary.push(col.key)
          }
          if (col.position == 'secondary') {
            this.cols_secondary.push(col)
          }

          if (!this.cols_by_key_loaded) {
            cols_by_key[col.key] = col
          }
        }
        if (!this.cols_by_key_loaded) {
          this.cols_by_key = cols_by_key
          this.cols_by_key_loaded = true
        }

        if (this.tags_count === null) {
          await this.load_tags()
        }

        this.saving_text = '저장'
        this.done = true

        if (this.record_id) {
          this.open_modal()
        }
        // this.open_filter_modal()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async add_document() {
      try {
        this.adding = true
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents`, {

        })
        if (r?.data?.message != 'ok') throw new Error('고객목록 추가 실패')
        this.load()
        setTimeout(() => {
          this.adding = false

        }, 300);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    row_updated() {
      console.log('reload from row')
      // this.tags_count = null
      this.load()
    }
  },
}
</script>
